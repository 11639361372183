[data-amplify-authenticator] [data-amplify-router] {
  border: 0;
  box-shadow: none;
  /* padding-left: 24px;
  padding-right: 24px; */
}
/* [data-amplify-container] {
  max-width: 120% !important;
} */

[data-amplify-router] {
  border: 0;
}

button.amplify-button.amplify-button--primary {
  background-color: #b71c1c;
}
